import { Component } from '@angular/core';
import {NgOptimizedImage} from "@angular/common";
import {TranslateModule} from "@ngx-translate/core";

@Component({
  selector: 'app-promo-section',
  standalone: true,
  providers:[

  ],
  imports: [
    NgOptimizedImage,
    TranslateModule
  ],
  templateUrl: './promo-section.component.html',
  styleUrl: './promo-section.component.scss'
})
export class PromoSectionComponent {
}
