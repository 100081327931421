import {Component, inject, OnInit, signal} from '@angular/core';
import {MatSidenavContainer, MatSidenavModule} from "@angular/material/sidenav";
import {MatToolbar} from "@angular/material/toolbar";
import {MatIcon} from "@angular/material/icon";
import {RouterOutlet} from "@angular/router";
import {MatIconButton} from "@angular/material/button";
import {BreakpointObserver, Breakpoints} from "@angular/cdk/layout";
import {SidebarMenuComponent} from "../sidebar-menu/sidebar-menu.component";

@Component({
  selector: 'app-main-layout',
  standalone: true,
  imports: [
    MatSidenavContainer,
    MatSidenavModule,
    SidebarMenuComponent,
    MatToolbar,
    MatIcon,
    RouterOutlet,
    MatIconButton
  ],
  templateUrl: './main-layout.component.html',
  styleUrl: './main-layout.component.scss'
})
export class MainLayoutComponent implements OnInit{
  isMobile = signal(true)
  breakPointObserver = inject(BreakpointObserver);

  ngOnInit() {
    this.breakPointObserver
      .observe([Breakpoints.Handset])
      .subscribe(result => this.isMobile.set(result.matches))
  }
}
