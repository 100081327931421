import { CanMatchFn } from '@angular/router';
import {inject} from "@angular/core";

import {UserStore} from "../store/user-store";

export const hasPermissionGuard: CanMatchFn = async (route) => {
  const userStore = inject(UserStore);
  await userStore.loadPermissions();
  if(route.data?.["permission"]){
    return userStore.hasPermission(route.data?.["permission"]);
  }
  return true;
};
