import { HttpInterceptorFn } from '@angular/common/http';
import {AuthService} from "../services/auth.service";
import {inject} from "@angular/core";
import {environment} from "../../../environments/environment";
import {catchError, from, switchMap} from "rxjs";

export const authInterceptor: HttpInterceptorFn = (req, next) => {
  const supraApi = environment.supraApi;
  const isSupraApiRequest = req.url.includes(supraApi);

  if (!isSupraApiRequest) {
    return next(req);
  }

  const authService = inject(AuthService);
  return from(authService.getAuthToken()).pipe(
    switchMap((authToken: string) => {
      const tokenExists = !!authToken;
      const newReq = tokenExists
        ? req.clone({
          headers: req.headers
            .set('Authorization', `Bearer ${authToken}`)
            .set('X-API-TYPE', 'public')
            .set('x-source', 'supra-app'),
        })
        : req;
      return next(newReq);
    }),
    catchError(() => {
      return next(req);
    })
  );
};
