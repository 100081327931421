import { Component } from '@angular/core';
import {RouterOutlet} from "@angular/router";
import {PromoSectionComponent} from "../../dump-components/promo-section/promo-section.component";

@Component({
  selector: 'app-auth-layout',
  standalone: true,
  imports: [
    RouterOutlet,
    PromoSectionComponent
  ],
  templateUrl: './auth-layout.component.html',
  styleUrl: './auth-layout.component.scss'
})
export class AuthLayoutComponent {

}
