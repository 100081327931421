import {Component, inject} from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { CoreModule } from "./core/core.module";
import { TranslateService } from "@ngx-translate/core";
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [
    RouterOutlet,
    CoreModule
  ],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  private translate = inject(TranslateService);
  private matIconRegistry = inject(MatIconRegistry);
  private domSanitizer = inject(DomSanitizer);

  constructor() {
    this.initializeTranslation()
    this.matIconRegistry.addSvgIconSetInNamespace(
      'supra',
      this.domSanitizer.bypassSecurityTrustResourceUrl('icons/supra-icon-set.svg')
    );
  }

  initializeTranslation(): void {
    const browserLang = navigator.language;
    if (browserLang.startsWith('en')) {
      this.translate.setDefaultLang('en');
    } else if (browserLang.startsWith('es')) {
      this.translate.setDefaultLang('es');
    } else {
      this.translate.setDefaultLang('es');
    }
  }
}
