import {CanActivateFn, Router} from '@angular/router';
import {inject} from "@angular/core";
import {AuthService} from "../services/auth.service";

export const AuthGuard: CanActivateFn = async () => {
  const router = inject(Router);
  const authService = inject(AuthService);

  try {
    const authToken = await authService.getAuthToken();
    if (authToken) {
      return true;
    }
  } catch (error) {
    console.error("Trying to access to a restricted area:", error);
  }

  await router.navigate(["/auth/login"]);
  return false;
};
