import {HttpInterceptorFn} from '@angular/common/http';
import {inject} from "@angular/core";
import {UserStore} from "../store/user-store";

export const publicApiWrapperInterceptor: HttpInterceptorFn = (req, next) => {
  let newUrl, modifiedBody;

  const privateUrlsWithoutUserId: string[] = [
    '/tr-user'
  ]

  if(privateUrlsWithoutUserId.some(url => req.url.includes(url))){
    return next(
      req.clone({
        url: req.url,
        body: modifiedBody ? modifiedBody : req.body,
        setHeaders: {
          "X-API-TYPE": "private"
        }
      })
    )
  }
  const privateUrls: string[] = [
    '/payments',
    '/v2/api/exchange/quote',
    'v1/api/exchange/convert',
    'v1/api/payin/payment',
    'v1/flows/payout/beneficiaries',
    '/v1/payout/currency-fee/',
    '/v1/api/payout/payment',
    '/v1/api/fields/'
  ];
  const privateApiRequestWithUserId = privateUrls.find(url => req.url.includes(url));
  if(privateApiRequestWithUserId) {
    const userStore = inject(UserStore);
    const userId =  userStore.userId();
    const companyId = userStore.companyId();
    if(!userId){
      return next(req)
    }

    if (req.url.includes(`/v1/payout/currency-fee/`)) {
      const currencyIdMatch = req.url.match(/\/v1\/payout\/currency-fee\/([^/]+)/);
      const currencyId = currencyIdMatch ? currencyIdMatch[1] : null;
      if (currencyId) {
        newUrl = req.url.replace(
          `/v1/payout/currency-fee/${currencyId}`,
          `/tr-fee-payout/currency/${currencyId}/company/${companyId}`
        );
      }
    }

    if(req.url.includes('/v1/api/fields/')){
      newUrl = req.url;
    }

    if (req.url.includes('/v1/flows/payout/beneficiaries')) {
      newUrl = req.url.replace('/v1/flows/payout/beneficiaries', `/v1/api/payout/user/${userId}/beneficiaries`);
    }

    if (req.url.includes('/payments')) {
      newUrl = req.url.replace('/payments', `/tr-payout?filter.userId=${userId}`);
    }

    if (req.url.includes('/payin/collections')) {
      newUrl = req.url.replace('/payin/collections', `/tr-deposit?filter.userId=${userId}&filter.status=$in:PARTIALLY_PAID,PAID`);
    }

    if(req.url.includes('/v2/api/exchange/quote')){
      newUrl = req.url;
      modifiedBody = {
        ...req.body as object,
        userId,
        provider: 'supra'
      };
    }
    if(req.url.includes('v1/api/exchange/convert')){
      newUrl = req.url;
      modifiedBody = {
        ...req.body as object,
        userId,
        provider: 'supra'
      };
    }
    if(req.url.includes('/v1/api/payout/payment')  && req.method === 'POST'){
      newUrl = req.url;
      modifiedBody = {
        ...req.body as object,
        userId
      };
    }
    if(req.url.includes('v1/api/payin/payment')){
      newUrl = req.url;
      modifiedBody = {
        ...req.body as object,
        userId,
        documentType: "CC",
        email: "farley.diaz@supra.la",
        cellPhone: "+573144711847",
        document: "1024540803",
        fullName: "Farley Diaz"
      };
    }

    if(newUrl){
      console.log("PRIVATE API CALL", newUrl)
      const modifiedReq = req.clone({
        url: newUrl,
        body: modifiedBody ? modifiedBody : req.body,
        setHeaders: {
          "X-API-TYPE": "private"
        }
      });
      return next(modifiedReq)
    }
  }




  return next(req);
};
